footer {
    border-top: 2px solid whitesmoke;
    position: relative;
}

a:any-link#link-f{
    text-decoration: none;
    color: black;
}

a:hover#link-f {
    color: -webkit-link;
    text-decoration-line: underline;
    text-decoration-color: -webkit-link;
}