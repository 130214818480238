.Impressum-H {
    color: #1AA037;
}

.Impressum-SubH {
    color: gray;
    font-weight: bold;
}

.Impressum-Font {
    color: black;
}

a:any-link.Impressum-L {
    color: #1AA037;
    font-weight: bold;
    text-decoration: none;
}

.icon {
    fill: gray;
}